<!-- Header.vue -->
<template>
	<Disclosure as="nav" class="bg-gray-800" v-slot="{ open }">
		  <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
			<div class="flex h-16 items-center justify-between">
			  <div class="flex items-center">
				<div class="flex-shrink-0">
				    <router-link to="/dashboard"><img class="h-12 w-12" src="../assets/83.svg" alt="83 Islands Dashboard" /></router-link>
				</div>
				<div class="hidden md:block">
<div class="ml-10 flex items-baseline space-x-4">
					<div v-for="item in navigation" :key="item.name" class="group relative">
						<router-link :to="item.href" :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'rounded-md px-3 py-2 text-sm font-medium']">{{ item.name }}</router-link>
						
						<div v-if="item.submenu" class="hidden absolute left-0 mt-1 p-1 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none group-hover:block z-50" tabindex="-1" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button">
							
							<div class="py-1" role="none">
								<router-link v-for="subitem in item.submenu" :key="subitem.name" :to="subitem.href" :class="[subitem.current ? 'bg-gray-900 text-white' : 'text-gray-900 hover:bg-gray-700 hover:text-white', 'block rounded-md px-3 py-2 text-sm font-medium']" role="menuitem">{{ subitem.name }}</router-link>
							</div>
						</div>
					</div>
				</div>
				
				</div>
			  </div>
		
			  <div class="hidden md:block">
				<div class="ml-4 flex items-center md:ml-6">
				  <button type="button" class="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
					<span class="sr-only">View notifications</span>
					<BellIcon class="h-6 w-6" aria-hidden="true" />
				  </button>
		
				</div>
			  </div>
			  
			  <div class="-mr-2 flex md:hidden">
			  </div>
			</div>
		  </div>
		
	
	</Disclosure>
	<div class="bg-white">
		<div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
 			 <div class="flow-root py-2">
    			<div class="-my-6 divide-y divide-gray-500/10">
					<div class="flex justify-between items-center py-6"> <!-- Adjusted for alignment -->
						<div class="flex items-center space-x-2">
							<router-link to="/floor" class="block rounded-lg px-2 py-1 text-sm font-semibold text-gray-900 hover:bg-gray-200">Floor</router-link>
							<router-link to="/incidentList" class="block rounded-lg px-2 py-1 text-sm font-semibold text-gray-900 hover:bg-gray-200" title="Safety, Non-conformance, and Enhancement Tracker">SNET</router-link>
							<router-link to="/sugarcaneFields" class="block rounded-lg px-2 py-1 text-sm font-semibold text-gray-900 hover:bg-gray-200">Sugarcane Fields</router-link>
							<router-link to="/sugarcaneDelivery" class="block rounded-lg px-2 py-1 text-sm font-semibold text-gray-900 hover:bg-gray-200">Sugarcane Delivery</router-link>
							<router-link to="/sugarcaneDashboard" class="block rounded-lg px-2 py-1 text-sm font-semibold text-gray-900 hover:bg-gray-200">Sugarcane Dashboard</router-link>
							<router-link to="/reception" class="block rounded-lg px-2 py-1 text-sm font-semibold text-gray-900 hover:bg-gray-200">Reception</router-link>
							<router-link to="/labels" class="block rounded-lg px-2 py-1 text-sm font-semibold text-gray-900 hover:bg-gray-200">Labels</router-link>
							<router-link to="/complianceDashboard" class="block rounded-lg px-2 py-1 text-sm font-semibold text-gray-900 hover:bg-gray-200">Compliance</router-link>
							<router-link to="/partnerDashboard" class="block rounded-lg px-2 py-1 text-sm font-semibold text-gray-900 hover:bg-gray-200">Stats</router-link>
							<div class="flex space-x-2">
								<a v-for="item in subNavigation" :key="item.name" :href="item.href" class="block rounded-lg px-2 py-1 text-sm font-semibold text-gray-900 hover:bg-gray-200">{{ item.name }}</a>
							</div>
							</div>
					<div class="text-right"> <!-- Message container -->
    					<span class="text-sm font-semibold text-gray-900">Company wide message here</span>
  					</div>
					</div>
    			</div>
  			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import { useRouter } from 'vue-router';
import { auth } from '@/firebase.js';
import { onAuthStateChanged } from 'firebase/auth';


const router = useRouter();

const loggedInUser = ref(null);

// Listen for authentication state changes
onMounted(() => {
  onAuthStateChanged(auth, (user) => { // Use the imported auth directly
    if (user) {
      loggedInUser.value = user;
      //console.log('User is logged in:', user);
    } else {
      loggedInUser.value = null;
      //console.log('User is logged out');
    }
  });
});


const createNewIncident = async () => {
  if (!loggedInUser.value) {
    console.error('No logged in user found');
    return;
  }

  try {
    const incidentData = {
      createdBy: loggedInUser.value.email,
      createdAt: new Date(),
    };

    // Make the Axios post request
    const response = await axios.post('/api/incidents', incidentData);

    // Log the response for debugging
    console.log('Axios response:', response);

    // Check if response and response.data are defined before accessing response.data._id
    if (response && response.data) {
      const newIncidentId = response.data._id;
      router.push({ name: 'IncidentCard', params: { id: newIncidentId } });
    } else {
      // Handle cases where response or response.data is undefined
      console.error('Unexpected response structure:', response);
    }
  } catch (error) {
    console.error('Error creating incident:', error);
    // Log the error response if available
    if (error.response) {
      console.log('Error details:', error.response.data);
    }
  }
};

const createNewSugarcaneDelivery = async () => {
  if (!loggedInUser.value) {
    console.error('No logged in user found');
    return;
  }

  try {
    const sugarcaneDeliveryData = {
      createdBy: loggedInUser.value.email,
      createdAt: new Date(),
    };

    // Make the Axios post request
    const response = await axios.post('/api/sugarcaneDelivery', sugarcaneDeliveryData);

    // Log the response for debugging
    console.log('Axios response:', response);

    // Check if response and response.data are defined before accessing response.data._id
    if (response && response.data) {
      const newSugarcaneDeliveryId = response.data._id;
      router.push({ name: 'SugarcaneDelivery', params: { id: newSugarcaneDeliveryId } });
    } else {
      // Handle cases where response or response.data is undefined
      console.error('Unexpected response structure:', response);
    }
  } catch (error) {
    console.error('Error creating sugarcane delivery:', error);
    // Log the error response if available
    if (error.response) {
      console.log('Error details:', error.response.data);
    }
  }
};

const navigation = [
  { name: 'Dashboard', href: '/dashboard', current: true },
  { name: 'Scheduler', href: '/scheduler', current: false, 
  submenu: [
  { name: 'Calendar', href: '/scheduler', current: false },
  { name: 'Daily Schedule [TV]', href: '/DailySchedule', current: false },
  ] 
  },
  { name: 'Production', href: '/prodDashboard', current: false
  },
  { name: 'Tours', href: '#', current: false, 
  submenu: [
    { name: 'List Tour', href: '/listTours', current: false },
  	{ name: 'Add Tour', href: '/addTour', current: false },
	{ name: 'Calendar Tour', href: '/calendarTour', current: false },
	{ name: 'TV Dashboard', href: '/tours', current: false },
  ] 
  },
  { name: 'Tasks', href: '#', current: false,
  submenu: [
	{ name: 'OFX', href: '/ofx', current: false },
	{ name: 'Task List', href: '/tasklist', current: false },
  ] },
  { name: 'Inventory', href: '#', current: false,
	submenu: [
	{ name: 'Woocommerce Duty Paid Sync', href: '/sync', current: false },
	{ name: 'Woocommerce Various Sync', href: '/syncvarious', current: false },	
	{ name: 'Stock Take', href: '/stocktake', current: false },
	{ name: 'CCA Live Unleashed', href: '/CCAList', current: false },
	{ name: 'CCA DB', href: '/CCADB', current: false },
	{ name: 'DE7', href: '/DE7', current: false },
	{ name: 'DE4', href: '#', current: false },
	{ name: 'Container', href: '/containers', current: false },
	{ name: 'Unleashed Stock Ajustment', href: '/wooStockAdj', current: false },
  ] 
  },
  { name: 'Sugarcane', href: '#', current: false,
  	submenu: [
	  { name: 'Farmer', href: '/fermentation', current: false },
	  { name: 'Field', href: '#', current: false },
	  { name: 'Sample', href: '#', current: false },
	  { name: 'Planning', href: '#', current: false },
	  { name: 'Delivery', href: '#', current: false },
	  { name: 'Report', href: '#', current: false },
	] 
	},
  {
	  name: 'Production',
	  href: '#',
	  current: false,
	  submenu: [
	  { name: 'Fermentation', href: '/fermentation', current: false },
	  { name: 'Fermentation List', href: '/fermentationList', current: false },
	  { name: 'Distillation', href: '/distillation', current: false },
	  { name: 'Distillation List', href: '/distillationList', current: false },
	  { name: 'Distillation Evaluation', href: '/distEval', current: false },
	  { name: 'Measurements List', href: '/measurementList', current: false },
		{ name: 'Barrel Batch', href: '/barrelBatch', current: false },
		{ name: 'Gin', href: '#', current: false },
		{ name: 'Liqueurs', href: '#', current: false },
		{ name: 'Bottling', href: '#', current: false },
		{ name: 'Equipment', href: '#', current: false },
	  ]
	},
	{ name: 'POS', href: '/pos', current: false,
  submenu: [
	{ name: 'Report', href: '/pos/report', current: false },
  ] },
  { name: 'Team', href: '/team', current: false},
  { name: 'Reports', href: '#', current: false,
  	submenu: [
	    { name: 'Sales Report', href: '/SalesReport', current: false },
	  	{ name: 'Product List', href: '/ProductList', current: false },
		{ name: 'Bar Presence', href: '/barpresence', current: false },
		{ name: 'DE7 Production', href: '/DE7production', current: false },
	  	{ name: 'DE4 Sales', href: '/DE4sale', current: false },
  		{ name: 'CCA Stock', href: '/CCAList', current: false },
  		{ name: 'Shop Stock', href: '#', current: false },
  		]
  },
  { name: 'Settings', href: '/Settings', current: false,
		submenu: [
			{ name: 'Users', href: '/Users', current: false },
			{ name: 'Payroll Settings', href: '/PayrollSettings', current: false },
			{ name: 'Advance', href: '/Advance', current: false },
			{ name: 'Environment Settings', href: '/Environmentsettings', current: false },
			{ name: 'Bonus', href: '/Bonus', current: false },
			{ name: 'Shift Logs', href: '/ShiftLogs', current: false },
			{ name: 'Pay Preparation', href: '/Pay/PayPrep', current: false },
			{ name: 'Timesheet Pay', href: '/TimesheetPay', current: false },
			{ name: 'Daily Timesheet Report', href: '/DailyTimesheetReport', current: false },
			{ name: 'Monthly Timesheet Report', href: '/MonthlyTimesheetReport', current: false },
			{ name: 'All Timesheet Report', href: '/AllTimesheetReport', current: false },
			{ name: 'Clock Validation', href: '/ClockValidation', current: false },
			{ name: 'Edit Employee', href: '/EditEmployee', current: false },
			{ name: 'Product Sync', href: '/ProductSync', current: false },
			//{ name: 'Recipe Settings', href: '/recipeSettings', current: false },
			] 
	},
]

const subNavigation = [
  { name: 'Blends', href: '#' },
]
</script>