// src/router/router.js
import { createRouter, createWebHistory } from 'vue-router';
import { auth } from '../firebase';
import SignIn from '../views/SignIn.vue';
import MainLayout from '../Layouts/MainLayout.vue';
import FullScreenLayout from '../Layouts/FullScreenLayout.vue';
import POSLayout from '../Layouts/POSLayout.vue';
import TableLayout from '../Layouts/TableLayout.vue';
import EventLayout from '../Layouts/EventLayout.vue';
import DeliveryConfirmation from '@/views/DeliveryConfirmation.vue';
import ClientView from '@/views/ClientView.vue';
import AltLayout from '../Layouts/AltLayout.vue';
import { useAuthStore } from '../stores/auth';

import { onAuthStateChanged } from "firebase/auth";

// Create authState as a module-scoped variable
const authState = {
	redirectPath: null,
	setRedirectPath(path) {
	  this.redirectPath = path;
	},
	clearRedirectPath() {
	  this.redirectPath = null;
	}
  };

const routes = [
	{
		path: '/login',
		component: SignIn,
		meta: {
			requiresAuth: false
		}
	},
	{
		path: '/loginQR',
		component: () => import('../views/SignInQR.vue'),
		meta: {
			requiresAuth: false,
			title: 'QR Code Authentification'
		},
	},	
	{
		path: '/loginQRPOS',
		component: () => import('../views/SignInQRPOS.vue'),
		meta: {
			requiresAuth: false,
			title: 'QR Code Authentification'
		},
	},	
	{
		path: '/QR',
		component: () => import('../views/QR.vue'),
		meta: {
			requiresAuth: false,
			title: 'QR Code Authentification'
		},
	},	
	{
		path: '/',
		component: MainLayout,
		redirect: '/dashboard',
		meta: {
			requiresAuth: true
		},
		children: [
			{
				path: 'dashboard',
				component: () => import('../views/Dashboard.vue'),
				meta: {
					requiresAuth: true,
					title: 'Dashboard'
				},
			},
			{
				path: '/delivery/:manifestNumber',
				name: 'DeliveryConfirmation',
				component: DeliveryConfirmation,
				meta: {
				  title: 'Delivery Confirmation'
				}
			},
			{
				path: 'prodDashboard',
				component: () => import('../views/prodDashboard.vue'),
				meta: {
					requiresAuth: true,
					title: 'Production Dashboard'
				},
			},
			{
				path: 'complianceDashboard',
				component: () => import('../views/ComplianceDashboard.vue'),
				meta: {
					requiresAuth: true,
					title: 'Compliance Dashboard'
				},
			},
			{
				path: 'ofx',
				component: () => import('../views/ofx.vue'),
				meta: {
					requiresAuth: true,
					title: 'Compliance Dashboard'
				},
			},
			{
				path: 'team',
				component: () => import('../views/team.vue'),
				meta: {
					requiresAuth: true,
					title: 'Team'
				},
			},
			{
				path: 'listtours',
				component: () => import('../views/listTours.vue'),
				meta: {
					requiresAuth: true,
					title: 'List Tours'
				},
			},
			{
				path: 'tasklist',
				component: () => import('../views/TaskList.vue'),
				meta: {
					requiresAuth: true,
					title: 'Task List'
				},
			},
			{
				path: '/taskcard/:id',
				name: 'TaskCard',
				component: () => import('../views/taskCard.vue'),
				meta: {
					requiresAuth: true,
					title: 'Edit Task'
				},
			},
			{
				path: 'CCADB',
				component: () => import('../views/CCADB.vue'),
				meta: {
					requiresAuth: true,
					title: 'CCA List DB'
				},
			},
			{
				path: 'sync',
				component: () => import('../views/InventorySync.vue'),
				meta: {
					requiresAuth: true,
					title: 'Duty Paid Inventory Sync - Unleashed - Woocommerce'
				},
			},
			{
				path: 'syncvarious',
				component: () => import('../views/InventorySyncVarious.vue'),
				meta: {
					requiresAuth: true,
					title: 'Various Inventory Sync - Unleashed - Woocommerce'
				},
			},
			{
				path: 'DE7',
				component: () => import('../views/DE7.vue'),
				meta: {
					requiresAuth: true,
					title: 'DE7 Declaration'
				},
			},

			{
				path: 'fermentationList',
				component: () => import('../views/FermentationList.vue'),
				meta: {
					title: 'Fermentations List'
				},
			},
			{
				path: 'distillationList',
				component: () => import('../views/DistillationList.vue'),
				meta: {
					title: 'Distillation List'
				},
			},
			{
				path: 'Editfermentation',
				component: () => import('../components/EditFermentation.vue'),
				meta: {
					title: 'Edit Fermentations'
				},
			},
			{
				path: 'EditDistillation',
				component: () => import('../components/EditDistillation.vue'),
				meta: {
					title: 'Edit Distillation'
				},
			},
			{
				path: 'barrelBatch',
				component: () => import('../views/barrelBatch.vue'),
				meta: {
					title: 'Barrel Batch'
				},
			},
			{
				path: '/barrelBatch/:id',
				name: 'BarrelBatchCard',
				component: () => import('../views/barrelBatchCard.vue'),
				meta: {
					title: 'Barrel Batch'
				},
			},
			{
				path: '/distEval',
				name: 'Distillation Evaluation',
				component: () => import('../views/distEval.vue'),
				meta: {
					title: 'Distillation Evaluation'
				},
			},
			{
				path: 'distEval/:id',
				name: 'DistEval',
				component: () => import('../views/distEval.vue'),
				meta: {
					title: 'Distillation Evaluation'
				},
			},
			{
				path: 'fermentation',
				component: () => import('../views/Fermentation.vue'),
				meta: {
					title: 'Fermentations'
				},
			},
			{
				path: 'salesreport',
				component: () => import('../views/SalesReport.vue'),
				meta: {
					title: 'Sales Report'
				},
			},
			{
				path: 'scheduler',
				component: () => import('../views/Scheduler.vue'),
				meta: {
					title: 'Scheduler'
				},
			},
			{
				path: 'distillation',
				component: () => import('../views/Distillation.vue'),
				meta: {
					title: 'Distillation'
				},
			},
			{
				path: '/distillation/:id',
				name: 'Distillation',
				component: () => import('@/views/Distillation.vue'),
				meta: {
					title: 'Distillation'
				},
			  },
			  {
				path: '/fermentation/:id',
				name: 'Fermentation',
				component: () => import('@/views/Fermentation.vue'),
				meta: {
					title: 'Fermentations'
				},
			  },
			{
				path: 'orders',
				component: () => import('../views/Orders.vue'),
				meta: {
					title: 'Orders'
				},
			},
			{
				path: 'measurementList',
				name: 'MeasurementList',
				component: () => import('../views/measurementList.vue'),
				meta: {
					title: 'Measurements'
				},
			},
			{
				path: '/measurement/:id',
				name: 'MeasurementCard',
				component: () => import('../views/measurementCard.vue'),
				meta: {
					title: 'Measurements'
				},
			},
			{
				path: '/incidentList',
				name: 'IncidentList',
				component: () => import('../views/IncidentList.vue'),
				meta: {
					title: 'SNET'
				},
			},
			{
				path: '/nonconformance/:id',
				name: 'NonConformanceCard',
				component: () => import('../views/nonconformanceCard.vue'),
				meta: {
					title: 'Non-Conformance Report'
				},
			},
			{
				path: '/incident/:id',
				name: 'IncidentCard',
				component: () => import('../views/IncidentCard.vue'),
				meta: {
					title: 'Incident Report'
				},
			},
			{
				path: '/enhancement/:id',
				name: 'EnhancementCard',
				component: () => import('../views/EnhancementCard.vue'),
				meta: {
					title: 'Enhancement Report'
				},
			},
			{
				path: '/recipeSettings',
				name: 'RecipeSettings',
				component: () => import('../views/RecipeSettings.vue'),
				meta: {
					title: 'RecipeSettings'
				},
			},
			{
				path: '/recipeSettings/:id',
				name: 'RecipeSettings',
				component: () => import('../views/RecipeSettingCard.vue'),
				meta: {
					title: 'Recipe Setting'
				},
			},
			{
				path: '/reception/:id',
				name: 'Reception',
				component: () => import('../views/Reception.vue'),
				meta: {
					title: 'Reception'
				},
			},
			{
				path: '/reception',
				name: 'ReceptionList',
				component: () => import('../views/ReceptionList.vue'),
				meta: {
					title: 'Reception List'
				},
			},
			{
				path: '/floor',
				name: 'Floor',
				component: () => import('../views/Floor.vue'),
				meta: {
					title: 'Floor'
				},
			},
			{
				path: '/containers',
				name: 'Containers',
				component: () => import('../views/Containers.vue'),
				meta: {
					title: 'Container'
				},
			},
			{
				path: '/containers/:id',
				name: 'EditContainer',
				component: () => import('../views/EditContainer.vue'),
				meta: {
					title: 'Edit Container'
				},
			},
			{
				path: '/sanitation',
				name: 'Sanitation',
				component: () => import('../views/Sanitation.vue'),
				meta: {
					title: 'Sanitation'
				},
			},
			{
				path: '/labels',
				name: 'Labels',
				component: () => import('../views/Labels.vue'),
				meta: {
					title: 'Labels'
				},
			},
			{
				path: '/sample/:id',
				name: 'SampleCard',
				component: () => import('../views/SampleCard.vue'),
				meta: {
					title: 'Sample'
				},
			},
			{
				path: '/sugarcaneDashboard/',
				name: 'SugarcaneDashboard',
				component: () => import('../views/SugarcaneDashboard.vue'),
				meta: {
					title: 'Sugarcane Dashboard'
				},
			},
			{
				path: '/sugarcaneFields/',
				name: 'SugarcaneFields',
				component: () => import('../views/SugarcaneFields.vue'),
				meta: {
					title: 'Sugarcane Fields'
				},
			},
			{
				path: '/sugarcaneDelivery/',
				name: 'SugarcaneDelivery',
				component: () => import('../views/SugarcaneDelivery.vue'),
				meta: {
					title: 'Sugarcane Delivery'
				},
			},
			{
				path: '/sugarcane/:id',
				name: 'SugarcaneDeliveryV0',
				component: () => import('../views/SugarcaneDeliveryV0.vue'),
				meta: {
					title: 'Sugarcane DeliveryV0'
				},
			},
			{
				path: 'calendarTour',
				component: () => import('../views/CalendarTour.vue'),
				meta: {
					title: 'Tour Calendar'
				},
			},
			{
			path: 'settings',
				component: () => import('../views/Settings.vue'),
				meta: {
					title: 'Settings'
				},
			},
			{
			path: 'environmentSettings',
				component: () => import('../views/Environmentsettings.vue'),
				meta: {
					title: 'Environment Settings'
				},
			},
			{
			path: 'ProductSync',
				component: () => import('../views/ProductSync.vue'),
				meta: {
					title: 'Product Sync'
				},
			},
			{
				path: 'Users',
					component: () => import('../views/Users.vue'),
					meta: {
						title: 'Users'
					},
				},
				{
					path: 'EditEmployee/:id?',
					name: 'EditEmployee',
					component: () => import('../views/EditEmployee.vue'),
					meta: {
					  title: 'Edit Users'
					},
				  },
				  {
					path: 'PayrollSettings',
					name: 'PayrollSettings',
					component: () => import('../views/PayrollSettings.vue'),
					meta: {
						title: 'Payroll Settings',
						requiresAuth: true,
						requiredRole: ['Admin', 'SuperUser']
					  },
				  },
				  {
					path: 'DailyTimesheetReport',
					name: 'DailyTimesheetReport',
					component: () => import('../views/DailyTimesheetReport.vue'),
					meta: {
						title: 'Daily Timesheet Report',
						requiresAuth: true,
						requiredRole: ['Admin', 'SuperUser']
					  },
				  },
				  {
					path: 'AllTimesheetReport',
					name: 'AllTimesheetReport',
					component: () => import('../views/AllTimesheetReport.vue'),
					meta: {
						title: 'All Timesheet Report',
						requiresAuth: true,
						requiredRole: ['Admin', 'SuperUser']
					  },
				  },
				  {
					path: 'MonthlyTimesheetReport',
					name: 'MonthlyTimesheetReport',
					component: () => import('../views/MonthlyTimesheetReport.vue'),
					meta: {
						title: 'Monthly Timesheet Report',
						requiresAuth: true,
						requiredRole: ['Admin', 'SuperUser']
					  },
				  },
				  {
					path: 'TimesheetPay',
					name: 'TimesheetPay',
					component: () => import('../views/TimesheetPay.vue'),
					meta: {
						title: 'Timesheet Pay',
						requiresAuth: true,
						requiredRole: ['Admin', 'SuperUser']
					  },
				  },
				  {
					path: 'Bonus',
					name: 'Bonus',
					component: () => import('../views/Bonus.vue'),
					meta: {
						title: 'Bonus',
						requiresAuth: true,
						requiredRole: ['Admin', 'SuperUser']
					  },
				  },
				  {
					path: 'Advance',
					name: 'Advance',
					component: () => import('../views/Advance.vue'),
					meta: {
						title: 'Advance',
						requiresAuth: true,
						requiredRole: ['Admin', 'SuperUser']
					  },
				  },
				  {
					path: 'ShiftLogs',
					name: 'ShiftLogs',
					component: () => import('../views/ShiftLogs.vue'),
					meta: {
						title: 'Shift Logs',
						requiresAuth: true,
						requiredRole: ['Admin', 'SuperUser']
					  },
				  },
				  {
					path: 'ClockValidation',
					name: 'ClockValidation',
					component: () => import('../views/ClockValidation.vue'),
					meta: {
						title: 'Clock Validation',
						requiresAuth: true,
						requiredRole: ['Admin', 'SuperUser']
					  },
				  },
				  {
					path: 'EditEmployee/:id?',
					name: 'EditEmployeeWithId',
					component: () => import('../views/EditEmployee.vue'),
					meta: {
					  title: 'Edit Users'
					},
				  },
			{
			path: 'addTour',
				component: () => import('../views/addTour.vue'),
				meta: {
					title: 'Add Tour'
				},
			},
			{
			path: 'modifyTour/:id',
			  component: () => import('../views/modifyTour.vue'),
			  meta: {
				title: 'Modify Tour'
			  },
			},
			{
			path: 'ccalist',
			component: () => import('../views/CCAList.vue'),
			meta: {
				title: 'CCA List'
				},
			},
			{
				path: 'stocktake',
				component: () => import('../views/StockTake.vue'),
				meta: {
					title: 'Stocktake'
					},
				},
			{
				path: 'barpresence',
				component: () => import('../views/BarPresence.vue'),
				meta: {
					title: 'Bar Presence'
					},
				},
			{
			path: 'DE7production',
			component: () => import('../views/DE7production.vue'),
			meta: {
				title: 'DE7 Production'
				},
			},
			{
				path: 'wooStockAdj',
				component: () => import('../views/CheckNewOrders.vue'),
				meta: {
					title: 'Unleashed Stock Adjustment'
					},
				},
			{
			path: 'DE4sale',
			component: () => import('../views/DE4sale.vue'),
			meta: {
				title: 'DE4 Sale'
				},
			},
			{
			path: 'ProductList',
			component: () => import('../views/ProductList.vue'),
			meta: {
				title: 'Product List'
			}
				},
				// ... other child routes for MainLayout
			]
	},

	{
		path: '/tours',
		component: FullScreenLayout,
		meta: {
		  requiresAuth: true,
		  title: 'Tours'
		},
		children: [
		  {
			path: '',
			component: () => import('../views/Tours.vue'),
			meta: {
			  requiresAuth: true,
			  title: 'Tours'
			},
		  },
		  {
			path: 'DailySchedule',
			component: () => import('../views/DailySchedule.vue'),
			meta: {
			  requiresAuth: true,
			  title: 'Daily Schedule'
			},
		  },
		],
	  },
	  {
		path: '/DailySchedule',
		component: FullScreenLayout,
		meta: {
		  requiresAuth: true,
		  title: 'Tours'
		},
		children: [
		  {
			path: '',
			component: () => import('../views/DailySchedule.vue'),
			meta: {
			  requiresAuth: true,
			  title: 'Tours'
			},
		  },
		],
	  },
	  {
		path: '/ES',
		component: FullScreenLayout,
		meta: {
		  requiresAuth: true,
		  title: 'Employee Station'
		},
		children: [
		  {
			path: '',
			component: () => import('../views/EmployeeStation.vue'),
			meta: {
			  requiresAuth: true,
			  title: 'Employee Station'
			},
		  },
		  {
			path: '/TimeTracker',
			component: () => import('../views/TimeTracker.vue'),
			meta: {
			  requiresAuth: true,
			  title: 'Time Tracker'
			},
		  },
		  {
			path: '/Timesheet',
			component: () => import('../views/TimesheetReport.vue'),
			meta: {
			  requiresAuth: true,
			  title: 'Timesheet Report'
			},
		  },
		],
	  },
	  {
		path: '/client',
		name: 'ClientView',
		component: ClientView
	  },
	  {
		path: '/POS',
		component: POSLayout,
		meta: {
		  requiresAuth: true,
		  title: 'Point of Sale'
		},
		children: [
			{
				path: '',
				component: () => import('../views/POS.vue'),
				meta: {
				  requiresAuth: true,
				  title: 'Point of Sale'
				},
			  },
			  {
				path: 'daily',
				component: () => import('../views/POSDaily.vue'),
				meta: {
				  requiresAuth: true,
				  title: 'Daily Report'
				},
			  },
			  {
				path: 'tableService',
				component: () => import('../views/POSTableService.vue'),
				meta: {
				  requiresAuth: true,
				  title: 'Table Service'
				},
			  },
			  {
				path: 'labels',
				component: () => import('../views/POSLabels.vue'),
				meta: {
				  requiresAuth: true,
				  title: 'POS labels'
				},
			  },
			  {
				path: 'closing/:id',
				component: () => import('../views/POSClosing.vue'),
				meta: {
				  requiresAuth: true,
				  title: 'Register Closing'
				},
			  },
			  {
				path: 'report',
				component: () => import('../views/POSReport.vue'),
				meta: {
				  requiresAuth: true,
				  title: 'POS Report'
				},
			  },
			  {
				path: '/order/:id',
				name: 'POSOrder', // Add a name to the route
				component: () => import('../views/POSorder.vue'),
				meta: {
				  requiresAuth: true,
				  title: 'POS Order'
				},
			  },
			  {
				path: 'bar',
				component: () => import('../views/POSbar.vue'),
				meta: {
				  requiresAuth: true,
				  title: 'POS BAR'
				},
			  },
		  
		],
	  },
	  {
		path: '/Pay',
		component: TableLayout,
		children: [
			{
				path: 'PayPrep',
				name: 'PayPrep',
				component: () => import('../views/PayPrep.vue'),
				meta: {
					title: 'Pay Preparation',
					requiresAuth: true,
					requiredRole: ['Admin', 'SuperUser']
				},
			},
		],
	},
	{
		path: '/event',
		component: EventLayout,
		meta: {
		  title: 'Event'
		},
		children: [
		  {
			path: 'admin',
			component: () => import('../views/EventAdmin.vue'),
			name: 'EventAdmin',
			meta: {
			  requiresAuth: true,
			  title: 'Event Admin'
			},
		  },
		  {
			path: 'admin/order',
			name: 'EventAdminOrder',
			component: () => import('../views/EventAdminOrder.vue'),
			meta: {
			  requiresAuth: true,
			  title: 'Admin Ordering'
			}
		  },
		  {
			path: 'admin/overview',
			name: 'EventAdminOverview',
			component: () => import('../views/EventAdminOverview.vue'),
			meta: {
			  requiresAuth: true,
			  title: 'Admin Ordering'
			}
		  },
		  {
			path: 'admin/tabs',
			name: 'EventAdminTabs',
			component: () => import('../views/EventAdminTabs.vue'),
			meta: {
			  requiresAuth: true,
			  title: 'Admin Tabs'
			}
		  },
		  {
			path: 'admin/table',
			name: 'EventAdminTable',
			component: () => import('../views/EventAdminTable.vue'),
			meta: {
			  requiresAuth: true,
			  title: 'Admin Table'
			}
		  },
		  {
			path: 'admin/edit/:id',
			name: 'EventAdminEdit',
			component: () => import('../views/EventAdminEdit.vue'),
			meta: {
			  requiresAuth: true,
			  title: 'Edit Event'
			}
		  },
		  {
			path: ':tableId',
			name: 'EventTableOrders',
			component: () => import('../views/EventTableOrders.vue'),
			meta: {
			  title: 'Table Orders'
			},
		  },
		  {
			path: 'order/:id',
			name: 'EventOrders',
			component: () => import('../views/EventOrder.vue'),
			meta: {
			  title: 'Event Orders'
			},
		  },
		  {
			path: 'order/:id/order-confirmation',
			name: 'OrderConfirmation',
			component: () => import('../views/EventOrderConfirmation.vue'),
			meta: {
			  title: 'Order Confirmation'
			},
		  },
		  {
			path: ':tableId/neworder',
			name: 'NewOrder',
			component: () => import('../views/EventTableNewOrders.vue'),
			props: true,
			meta: {
			  title: 'New Order'
			}
		  },
		],
	  },
	  {
        path: '/',
        component: AltLayout,
        children: [
          {
            path: '/partnerDashboard',
            component: () => import('../views/partnerDashboard.vue'),
            meta: {
              requiresAuth: true,
              title: 'Partners Dashboard'
            },
          },
        ],
      },

];	

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
  });
  
const authStateReady = new Promise((resolve) => {
	onAuthStateChanged(auth, (user) => {
	  resolve(user);
	});
});

router.beforeEach(async (to, from, next) => {
await authStateReady;

const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
const currentUser = auth.currentUser;

console.log('Route navigation:', { to: to.fullPath, requiresAuth, currentUser: !!currentUser });

if (requiresAuth && !currentUser) {
	console.log('Redirecting to login, saving path:', to.fullPath);
	authState.setRedirectPath(to.fullPath);
	next('/login');
} else if (to.path === '/login' && currentUser) {
	const redirectPath = authState.redirectPath || '/dashboard';
	console.log('User already logged in, redirecting to:', redirectPath);
	authState.clearRedirectPath();
	next(redirectPath);
} else {
	console.log('Proceeding to:', to.fullPath);
	next();
}
});
  
let isAuthReady = false; // Flag to check if auth state has been checked at least once

auth.onAuthStateChanged(() => {
if (!isAuthReady) {
	isAuthReady = true;
}
});

export { router as default, authState };